import React from 'react';

function Auction () {

    return (
        <div>
            {/*<img style={{ maxHeight : 'unset' }} src={`${process.env.PUBLIC_URL}/images/comingsoon.jpeg`} alt={'comingsoon'} className="topic-image" />*/}

            
        </div>
    )
}

export default Auction;