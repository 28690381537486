// src/components/CountryList.js

const CountryList = () => {
  return [
    { value: 'AFG', label: 'Afghanistan', countryCode: '+93', ports: ['Kabul Port (Inland)'] },
    { value: 'ALB', label: 'Albania', countryCode: '+355', ports: ['Port of Durrës', 'Port of Vlorë'] },
    { value: 'DZA', label: 'Algeria', countryCode: '+213', ports: ['Algiers', 'Oran', 'Annaba'] },
    { value: 'AND', label: 'Andorra', countryCode: '+376', ports: [] },
    { value: 'AGO', label: 'Angola', countryCode: '+244', ports: ['Luanda', 'Namibe', 'Lobito'] },
    { value: 'ARG', label: 'Argentina', countryCode: '+54', ports: ['Buenos Aires', 'Rosario', 'Mar del Plata'] },
    { value: 'ARM', label: 'Armenia', countryCode: '+374', ports: [] },
    { value: 'AUS', label: 'Australia', countryCode: '+61', ports: ['Sydney', 'Melbourne', 'Brisbane', 'Perth'] },
    { value: 'AUT', label: 'Austria', countryCode: '+43', ports: ['Vienna'] },
    { value: 'AZE', label: 'Azerbaijan', countryCode: '+994', ports: ['Baku'] },
    { value: 'BHS', label: 'Bahamas', countryCode: '+1-242', ports: ['Nassau', 'Freeport'] },
    { value: 'BHR', label: 'Bahrain', countryCode: '+973', ports: ['Manama'] },
    { value: 'BGD', label: 'Bangladesh', countryCode: '+880', ports: ['Chittagong', 'Dhaka'] },
    { value: 'BRB', label: 'Barbados', countryCode: '+1-246', ports: ['Bridgetown'] },
    { value: 'BLR', label: 'Belarus', countryCode: '+375', ports: [] },
    { value: 'BEL', label: 'Belgium', countryCode: '+32', ports: ['Antwerp', 'Zeebrugge'] },
    { value: 'BLZ', label: 'Belize', countryCode: '+501', ports: ['Belize City'] },
    { value: 'BEN', label: 'Benin', countryCode: '+229', ports: ['Cotonou'] },
    { value: 'BTN', label: 'Bhutan', countryCode: '+975', ports: [] },
    { value: 'BOL', label: 'Bolivia', countryCode: '+591', ports: [] },
    { value: 'BES', label: 'Bonaire, Sint Eustatius and Saba', countryCode: '+599', ports: ['Kralendijk'] },
    { value: 'BIH', label: 'Bosnia and Herzegovina', countryCode: '+387', ports: ['Ploče', 'Neum'] },
    { value: 'BWA', label: 'Botswana', countryCode: '+267', ports: [] },
    { value: 'BVT', label: 'Bouvet Island', countryCode: '+47', ports: [] },
    { value: 'BRA', label: 'Brazil', countryCode: '+55', ports: ['Santos', 'Rio de Janeiro', 'Salvador'] },
    { value: 'VGB', label: 'British Virgin Islands', countryCode: '+1-284', ports: ['Road Town'] },
    { value: 'BRN', label: 'Brunei Darussalam', countryCode: '+673', ports: ['Muara'] },
    { value: 'BGR', label: 'Bulgaria', countryCode: '+359', ports: ['Varna', 'Burgas'] },
    { value: 'BFA', label: 'Burkina Faso', countryCode: '+226', ports: [] },
    { value: 'BDI', label: 'Burundi', countryCode: '+257', ports: [] },
    { value: 'CPV', label: 'Cabo Verde', countryCode: '+238', ports: ['Mindelo'] },
    { value: 'KHM', label: 'Cambodia', countryCode: '+855', ports: ['Sihanoukville'] },
    { value: 'CMR', label: 'Cameroon', countryCode: '+237', ports: ['Douala', 'Limbe'] },
    { value: 'CAN', label: 'Canada', countryCode: '+1', ports: ['Vancouver', 'Toronto', 'Halifax'] },
    { value: 'CYM', label: 'Cayman Islands', countryCode: '+1-345', ports: ['George Town'] },
    { value: 'CAF', label: 'Central African Republic', countryCode: '+236', ports: [] },
    { value: 'TCD', label: 'Chad', countryCode: '+235', ports: [] },
    { value: 'CHL', label: 'Chile', countryCode: '+56', ports: ['Valparaíso', 'San Antonio'] },
    { value: 'CHN', label: 'China', countryCode: '+86', ports: ['Shanghai', 'Hong Kong', 'Shenzhen'] },
    { value: 'CXR', label: 'Christmas Island', countryCode: '+61', ports: [] },
    { value: 'CCK', label: 'Cocos (Keeling) Islands', countryCode: '+61', ports: [] },
    { value: 'COL', label: 'Colombia', countryCode: '+57', ports: ['Cartagena', 'Barranquilla'] },
    { value: 'COM', label: 'Comoros', countryCode: '+269', ports: ['Moroni'] },
    { value: 'COG', label: 'Congo', countryCode: '+242', ports: ['Pointe-Noire'] },
    { value: 'COK', label: 'Cook Islands', countryCode: '+682', ports: ['Avarua'] },
    { value: 'CRI', label: 'Costa Rica', countryCode: '+506', ports: ['Limón', 'Puerto Caldera'] },
    { value: 'CIV', label: "Côte d'Ivoire", countryCode: '+225', ports: ['Abidjan'] },
    { value: 'HRV', label: 'Croatia', countryCode: '+385', ports: ['Rijeka', 'Split', 'Dubrovnik'] },
    { value: 'CUB', label: 'Cuba', countryCode: '+53', ports: ['Havana', 'Santiago de Cuba'] },
    { value: 'CUW', label: 'Curaçao', countryCode: '+599', ports: ['Willemstad'] },
    { value: 'CYP', label: 'Cyprus', countryCode: '+357', ports: ['Limassol', 'Larnaca'] },
    { value: 'CZE', label: 'Czech Republic', countryCode: '+420', ports: [] },
    { value: 'DNK', label: 'Denmark', countryCode: '+45', ports: ['Copenhagen', 'Aarhus'] },
    { value: 'DJI', label: 'Djibouti', countryCode: '+253', ports: ['Djibouti City'] },
    { value: 'DMA', label: 'Dominica', countryCode: '+1-767', ports: ['Roseau'] },
    { value: 'DOM', label: 'Dominican Republic', countryCode: '+1-809', ports: ['Santo Domingo', 'Puerto Plata'] },
    { value: 'ECU', label: 'Ecuador', countryCode: '+593', ports: ['Guayaquil', 'Esmeraldas'] },
    { value: 'EGY', label: 'Egypt', countryCode: '+20', ports: ['Alexandria', 'Port Said', 'Suez'] },
    { value: 'SLV', label: 'El Salvador', countryCode: '+503', ports: ['Acajutla'] },
    { value: 'GNQ', label: 'Equatorial Guinea', countryCode: '+240', ports: ['Malabo'] },
    { value: 'ERI', label: 'Eritrea', countryCode: '+291', ports: ['Asmara'] },
    { value: 'EST', label: 'Estonia', countryCode: '+372', ports: ['Tallinn'] },
    { value: 'SWZ', label: 'Eswatini', countryCode: '+268', ports: [] },
    { value: 'ETH', label: 'Ethiopia', countryCode: '+251', ports: [] },
    { value: 'FLK', label: 'Falkland Islands (Malvinas)', countryCode: '+500', ports: ['Stanley'] },
    { value: 'FRO', label: 'Faroe Islands', countryCode: '+298', ports: ['Tórshavn'] },
    { value: 'FJI', label: 'Fiji', countryCode: '+679', ports: ['Suva'] },
    { value: 'FIN', label: 'Finland', countryCode: '+358', ports: ['Helsinki'] },
    { value: 'FRA', label: 'France', countryCode: '+33', ports: ['Marseille', 'Le Havre', 'Bordeaux'] },
    { value: 'GAB', label: 'Gabon', countryCode: '+241', ports: ['Libreville'] },
    { value: 'GMB', label: 'Gambia', countryCode: '+220', ports: ['Banjul'] },
    { value: 'GEO', label: 'Georgia', countryCode: '+995', ports: ['Batumi', 'Poti'] },
    { value: 'DEU', label: 'Germany', countryCode: '+49', ports: ['Hamburg', 'Bremerhaven'] },
    { value: 'GHA', label: 'Ghana', countryCode: '+233', ports: ['Tema', 'Takoradi'] },
    { value: 'GIB', label: 'Gibraltar', countryCode: '+350', ports: ['Gibraltar'] },
    { value: 'GRC', label: 'Greece', countryCode: '+30', ports: ['Piraeus', 'Thessaloniki'] },
    { value: 'GRL', label: 'Greenland', countryCode: '+299', ports: ['Nuuk'] },
    { value: 'GRD', label: 'Grenada', countryCode: '+1-473', ports: ['St. George’s'] },
    { value: 'GLP', label: 'Guadeloupe', countryCode: '+590', ports: ['Pointe-à-Pitre'] },
    { value: 'GUM', label: 'Guam', countryCode: '+1-671', ports: ['Hagåtña'] },
    { value: 'GTM', label: 'Guatemala', countryCode: '+502', ports: ['Puerto Quetzal'] },
    { value: 'GIN', label: 'Guinea', countryCode: '+224', ports: ['Conakry'] },
    { value: 'GNB', label: 'Guinea-Bissau', countryCode: '+245', ports: ['Bissau'] },
    { value: 'GUY', label: 'Guyana', countryCode: '+592', ports: ['Georgetown'] },
    { value: 'HTI', label: 'Haiti', countryCode: '+509', ports: ['Port-au-Prince'] },
    { value: 'HMD', label: 'Heard Island and McDonald Islands', countryCode: '+61', ports: [] },
    { value: 'HND', label: 'Honduras', countryCode: '+504', ports: ['Puerto Cortés'] },
    { value: 'HUN', label: 'Hungary', countryCode: '+36', ports: [] },
    { value: 'ISL', label: 'Iceland', countryCode: '+354', ports: ['Reykjavik'] },
    { value: 'IND', label: 'India', countryCode: '+91', ports: ['Mumbai', 'Chennai', 'Kolkata'] },
    { value: 'IDN', label: 'Indonesia', countryCode: '+62', ports: ['Jakarta', 'Surabaya', 'Makassar'] },
    { value: 'IRN', label: 'Iran', countryCode: '+98', ports: ['Bandar Abbas', 'Bushehr'] },
    { value: 'IRQ', label: 'Iraq', countryCode: '+964', ports: ['Basra'] },
    { value: 'IRL', label: 'Ireland', countryCode: '+353', ports: ['Dublin', 'Cork'] },
    { value: 'IMN', label: 'Isle of Man', countryCode: '+44-1624', ports: ['Douglas'] },
    { value: 'ISR', label: 'Israel', countryCode: '+972', ports: ['Haifa', 'Ashdod'] },
    { value: 'ITA', label: 'Italy', countryCode: '+39', ports: ['Genoa', 'Venice', 'Naples'] },
    { value: 'JAM', label: 'Jamaica', countryCode: '+1-876', ports: ['Kingston', 'Montego Bay'] },
    { value: 'JPN', label: 'Japan', countryCode: '+81', ports: ['Tokyo', 'Yokohama', 'Kobe'] },
    { value: 'JEY', label: 'Jersey', countryCode: '+44-1534', ports: ['St. Helier'] },
    { value: 'JOR', label: 'Jordan', countryCode: '+962', ports: ['Aqaba'] },
    { value: 'KAZ', label: 'Kazakhstan', countryCode: '+7', ports: ['Aktau'] },
    { value: 'KEN', label: 'Kenya', countryCode: '+254', ports: ['Mombasa'] },
    { value: 'KIR', label: 'Kiribati', countryCode: '+686', ports: ['Tarawa'] },
    { value: 'PRK', label: 'Korea (Democratic People\'s Republic of)', countryCode: '+850', ports: ['Nampo'] },
    { value: 'KOR', label: 'Korea (Republic of)', countryCode: '+82', ports: ['Busan', 'Incheon'] },
    { value: 'KWT', label: 'Kuwait', countryCode: '+965', ports: ['Shuwaikh', 'Ahmadi'] },
    { value: 'KGZ', label: 'Kyrgyzstan', countryCode: '+996', ports: [] },
    { value: 'LAO', label: 'Lao People\'s Democratic Republic', countryCode: '+856', ports: [] },
    { value: 'LVA', label: 'Latvia', countryCode: '+371', ports: ['Riga'] },
    { value: 'LBN', label: 'Lebanon', countryCode: '+961', ports: ['Beirut'] },
    { value: 'LSO', label: 'Lesotho', countryCode: '+266', ports: [] },
    { value: 'LBR', label: 'Liberia', countryCode: '+231', ports: ['Monrovia'] },
    { value: 'LBY', label: 'Libya', countryCode: '+218', ports: ['Tripoli', 'Benghazi'] },
    { value: 'LIE', label: 'Liechtenstein', countryCode: '+423', ports: [] },
    { value: 'LTU', label: 'Lithuania', countryCode: '+370', ports: ['Klaipeda'] },
    { value: 'LUX', label: 'Luxembourg', countryCode: '+352', ports: [] },
    { value: 'MAC', label: 'Macao', countryCode: '+853', ports: [] },
    { value: 'MKD', label: 'North Macedonia', countryCode: '+389', ports: [] },
    { value: 'MDG', label: 'Madagascar', countryCode: '+261', ports: ['Tamatave'] },
    { value: 'MWI', label: 'Malawi', countryCode: '+265', ports: [] },
    { value: 'MYS', label: 'Malaysia', countryCode: '+60', ports: ['Kuala Lumpur', 'Penang', 'Port Klang'] },
    { value: 'MDV', label: 'Maldives', countryCode: '+960', ports: ['Malé'] },
    { value: 'MLI', label: 'Mali', countryCode: '+223', ports: [] },
    { value: 'MLT', label: 'Malta', countryCode: '+356', ports: ['Valletta'] },
    { value: 'MHL', label: 'Marshall Islands', countryCode: '+692', ports: ['Majuro'] },
    { value: 'MTQ', label: 'Martinique', countryCode: '+596', ports: ['Fort-de-France'] },
    { value: 'MRT', label: 'Mauritania', countryCode: '+222', ports: ['Nouakchott'] },
    { value: 'MUS', label: 'Mauritius', countryCode: '+230', ports: ['Port Louis'] },
    { value: 'MYT', label: 'Mayotte', countryCode: '+262', ports: [] },
    { value: 'MEX', label: 'Mexico', countryCode: '+52', ports: ['Veracruz', 'Altamira', 'Manzanillo'] },
    { value: 'FSM', label: 'Micronesia (Federated States of)', countryCode: '+691', ports: ['Pohnpei'] },
    { value: 'MDA', label: 'Moldova', countryCode: '+373', ports: [] },
    { value: 'MCO', label: 'Monaco', countryCode: '+377', ports: ['Monaco'] },
    { value: 'MNG', label: 'Mongolia', countryCode: '+976', ports: [] },
    { value: 'MNE', label: 'Montenegro', countryCode: '+382', ports: ['Bar'] },
    { value: 'MSR', label: 'Montserrat', countryCode: '+1-664', ports: ['Plymouth'] },
    { value: 'MAR', label: 'Morocco', countryCode: '+212', ports: ['Casablanca', 'Tangier'] },
    { value: 'MOZ', label: 'Mozambique', countryCode: '+258', ports: ['Maputo', 'Beira'] },
    { value: 'MMR', label: 'Myanmar', countryCode: '+95', ports: ['Yangon'] },
    { value: 'NAM', label: 'Namibia', countryCode: '+264', ports: ['Walvis Bay'] },
    { value: 'NRU', label: 'Nauru', countryCode: '+674', ports: [] },
    { value: 'NPL', label: 'Nepal', countryCode: '+977', ports: [] },
    { value: 'NLD', label: 'Netherlands', countryCode: '+31', ports: ['Rotterdam', 'Amsterdam'] },
    { value: 'NCL', label: 'New Caledonia', countryCode: '+687', ports: ['Nouméa'] },
    { value: 'NZL', label: 'New Zealand', countryCode: '+64', ports: ['Auckland', 'Wellington', 'Christchurch'] },
    { value: 'NIC', label: 'Nicaragua', countryCode: '+505', ports: ['Corinto'] },
    { value: 'NER', label: 'Niger', countryCode: '+227', ports: [] },
    { value: 'NGA', label: 'Nigeria', countryCode: '+234', ports: ['Lagos', 'Port Harcourt'] },
    { value: 'NIU', label: 'Niue', countryCode: '+683', ports: [] },
    { value: 'NFK', label: 'Norfolk Island', countryCode: '+672', ports: [] },
    { value: 'NOR', label: 'Norway', countryCode: '+47', ports: ['Oslo', 'Bergen'] },
    { value: 'OMN', label: 'Oman', countryCode: '+968', ports: ['Muscat'] },
    { value: 'PAK', label: 'Pakistan', countryCode: '+92', ports: ['Karachi', 'Gwadar'] },
    { value: 'PLW', label: 'Palau', countryCode: '+680', ports: ['Malakal'] },
    { value: 'PAN', label: 'Panama', countryCode: '+507', ports: ['Balboa', 'Colón'] },
    { value: 'PNG', label: 'Papua New Guinea', countryCode: '+675', ports: ['Port Moresby'] },
    { value: 'PRY', label: 'Paraguay', countryCode: '+595', ports: [] },
    { value: 'PER', label: 'Peru', countryCode: '+51', ports: ['Callao'] },
    { value: 'PHL', label: 'Philippines', countryCode: '+63', ports: ['Manila', 'Cebu'] },
    { value: 'POL', label: 'Poland', countryCode: '+48', ports: ['Gdynia', 'Gdańsk'] },
    { value: 'PRI', label: 'Puerto Rico', countryCode: '+1-787', ports: ['San Juan'] },
    { value: 'PRT', label: 'Portugal', countryCode: '+351', ports: ['Lisbon', 'Porto'] },
    { value: 'QAT', label: 'Qatar', countryCode: '+974', ports: ['Doha'] },
    { value: 'ROU', label: 'Romania', countryCode: '+40', ports: ['Constanța'] },
    { value: 'RUS', label: 'Russia', countryCode: '+7', ports: ['Saint Petersburg', 'Novorossiysk'] },
    { value: 'RWA', label: 'Rwanda', countryCode: '+250', ports: [] },
    { value: 'KNA', label: 'Saint Kitts and Nevis', countryCode: '+1-869', ports: ['Basseterre'] },
    { value: 'LCA', label: 'Saint Lucia', countryCode: '+1-758', ports: ['Castries'] },
    { value: 'VCT', label: 'Saint Vincent and the Grenadines', countryCode: '+1-784', ports: ['Kingstown'] },
    { value: 'WSM', label: 'Samoa', countryCode: '+685', ports: ['Apia'] },
    { value: 'SMR', label: 'San Marino', countryCode: '+378', ports: [] },
    { value: 'STP', label: 'Sao Tome and Principe', countryCode: '+239', ports: ['Sao Tome'] },
    { value: 'SAU', label: 'Saudi Arabia', countryCode: '+966', ports: ['Jeddah', 'Dammam'] },
    { value: 'SEN', label: 'Senegal', countryCode: '+221', ports: ['Dakar'] },
    { value: 'SRB', label: 'Serbia', countryCode: '+381', ports: [] },
    { value: 'SYC', label: 'Seychelles', countryCode: '+248', ports: ['Victoria'] },
    { value: 'SLE', label: 'Sierra Leone', countryCode: '+232', ports: ['Freetown'] },
    { value: 'SGP', label: 'Singapore', countryCode: '+65', ports: ['Singapore'] },
    { value: 'SVK', label: 'Slovakia', countryCode: '+421', ports: [] },
    { value: 'SVN', label: 'Slovenia', countryCode: '+386', ports: [] },
    { value: 'SLB', label: 'Solomon Islands', countryCode: '+677', ports: ['Honiara'] },
    { value: 'SOM', label: 'Somalia', countryCode: '+252', ports: ['Mogadishu'] },
    { value: 'ZAF', label: 'South Africa', countryCode: '+27', ports: ['Durban', 'Cape Town'] },
    { value: 'SSD', label: 'South Sudan', countryCode: '+211', ports: [] },
    { value: 'ESP', label: 'Spain', countryCode: '+34', ports: ['Barcelona', 'Valencia', 'Algeciras'] },
    { value: 'LKA', label: 'Sri Lanka', countryCode: '+94', ports: ['Colombo'] },
    { value: 'SDN', label: 'Sudan', countryCode: '+249', ports: [] },
    { value: 'SUR', label: 'Suriname', countryCode: '+597', ports: ['Paramaribo'] },
    { value: 'SWE', label: 'Sweden', countryCode: '+46', ports: ['Stockholm', 'Gothenburg'] },
    { value: 'CHE', label: 'Switzerland', countryCode: '+41', ports: [] },
    { value: 'SYR', label: 'Syrian Arab Republic', countryCode: '+963', ports: ['Latakia', 'Tartus'] },
    { value: 'TWN', label: 'Taiwan', countryCode: '+886', ports: ['Kaohsiung', 'Taipei'] },
    { value: 'TJK', label: 'Tajikistan', countryCode: '+992', ports: [] },
    { value: 'TZA', label: 'Tanzania', countryCode: '+255', ports: ['Dar es Salaam'] },
    { value: 'THA', label: 'Thailand', countryCode: '+66', ports: ['Bangkok', 'Laem Chabang'] },
    { value: 'TLS', label: 'Timor-Leste', countryCode: '+670', ports: [] },
    { value: 'TGO', label: 'Togo', countryCode: '+228', ports: ['Lomé'] },
    { value: 'TON', label: 'Tonga', countryCode: '+676', ports: ['Nukuʻalofa'] },
    { value: 'TUR', label: 'Turkey', countryCode: '+90', ports: ['Istanbul', 'Izmir'] },
    { value: 'TKM', label: 'Turkmenistan', countryCode: '+993', ports: [] },
    { value: 'TCA', label: 'Turks and Caicos Islands', countryCode: '+1-649', ports: ['Cockburn Town'] },
    { value: 'TUV', label: 'Tuvalu', countryCode: '+688', ports: ['Funafuti'] },
    { value: 'UGA', label: 'Uganda', countryCode: '+256', ports: ['Entebbe'] },
    { value: 'UKR', label: 'Ukraine', countryCode: '+380', ports: ['Odessa', 'Mykolaiv'] },
    { value: 'ARE', label: 'United Arab Emirates', countryCode: '+971', ports: ['Dubai', 'Abu Dhabi'] },
    { value: 'GBR', label: 'United Kingdom', countryCode: '+44', ports: ['London', 'Liverpool', 'Southampton'] },
    { value: 'USA', label: 'United States', countryCode: '+1', ports: ['New York', 'Los Angeles', 'Miami'] },
    { value: 'URY', label: 'Uruguay', countryCode: '+598', ports: ['Montevideo'] },
    { value: 'UZB', label: 'Uzbekistan', countryCode: '+998', ports: [] },
    { value: 'VUT', label: 'Vanuatu', countryCode: '+678', ports: ['Port Vila'] },
    { value: 'VEN', label: 'Venezuela', countryCode: '+58', ports: ['La Guaira', 'Puerto Cabello'] },
    { value: 'VNM', label: 'Viet Nam', countryCode: '+84', ports: ['Ho Chi Minh City', 'Hai Phong'] },
    { value: 'YEM', label: 'Yemen', countryCode: '+967', ports: ['Aden'] },
    { value: 'ZMB', label: 'Zambia', countryCode: '+260', ports: [] },
    { value: 'ZWE', label: 'Zimbabwe', countryCode: '+263', ports: [] }
]
};

export default CountryList;